<template>
    <div class="container">
      <el-row style="background-color: #ffffff">
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="课程管理" name="0">
                <courseList></courseList>
        </el-tab-pane>
        <el-tab-pane label="服务管理" name="1">
                <serviceList></serviceList>
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
import courseList from './components/course/courseList.vue';
import serviceList from './components/service/serviceList.vue';

  export default {
    components: {
      courseList,
      serviceList
    },
    data() {
      return {
        nav: { firstNav: "课程管理中心", secondNav: "课程/服务管理" },
        activeName: 0,
      }
    },
    methods: {
        handleClick() {},
    }
  };
  </script>
  
  <style lang="less">
 
  </style>
  