<template>
  <!-- 社区机构课程 编辑 -->
  <div>
    <el-dialog
      :visible.sync="addDialog"
      :title="mode == 'edit' ? '修改课程' : '新增课程'"
      width="60%"
    >
      <el-form :model="form" label-width="auto" ref="myForm" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="课程类型">
              <el-select v-model="form.commodity_type" @change="changeCourseType">
                <el-option value="社区基础课" label="社区基础课"></el-option>
                <el-option
                  value="社区公开课"
                  label="社区公开课(免费)"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程名称" prop="title">
              <el-input v-model="form.title" placeholder="课程名称"></el-input>
                </el-form-item>
              <el-form-item label="课程类目" prop="categories_child">
                <el-select
                  v-model="form.categories_child"
                  placeholder="课程类目"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in categories_child_list"
                    :key="index"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属社区机构" required v-if="mode == 'edit'">
                <el-select
                  v-model="mechanism_id"
                  :placeholder="
                    !form.map
                      ? '输入机构名称或手机号'
                      : form.map.masterMechanismEntity.mechanism_name
                  "
                  filterable
                  clearable
                  remote
                  :remote-method="searchOrgName"
                >
                  <el-option
                    v-for="(item, index) in mechanism_name_list"
                    :key="index"
                    :label="item.mechanism_name"
                    :value="item.id"
                    @click.native="getAddress(item)"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属社区机构" required v-else>
                <el-select
                  v-model="select_mechanism"
                  collapse-tags
                  multiple
                  placeholder="输入机构名称或手机号"
                  filterable
                  clearable
                  remote
                  :remote-method="searchOrgName"
                >
                  <el-option
                    v-for="(item, index) in mechanism_name_list"
                    :key="index"
                    :label="item.mechanism_name"
                    :value="item.id"
                    @click.native="getAddress(item)"
                  ></el-option>
                </el-select>
                <el-checkbox
                  style="margin-left: 6px"
                  v-model="checked"
                  @change="selectAll"
                  >全选</el-checkbox
                >
            
            </el-form-item>
            <el-form-item label="签约教练">
              <coachSelect
              :placeholder="form.sign_coach_id"
                :coachid.sync="coach_id"
                :condition="{ coach_role: 'sign' }"
              ></coachSelect>
            </el-form-item>
            <el-form-item required label="正课节数" prop="formal_course_num">
              <el-input placeholder="正课节数" v-model="form.formal_course_num">
              </el-input>
            </el-form-item>
            <el-form-item
              label="天赋课节数"
              v-if="form.commodity_type == '社区基础课'"
            >
              <el-input
                placeholder="天赋课节数，没有不填或填0"
                v-model="form.settlement_course"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="单节市场价"
              v-if="form.commodity_type == '社区基础课'"
              prop="amout"
            >
              <el-input v-model="form.single_amout"></el-input>
              <span>元</span>
            </el-form-item>
            <el-form-item
              label="门店市场总价"
              v-if="form.commodity_type == '社区公开课'"
            >
              <el-input v-model="form.amout" placeholder="市场总价"> </el-input
              ><span class="form_text">元</span>
            </el-form-item>
            <el-form-item
              label="非业主价格"
              v-if="form.commodity_type == '社区公开课'"
            >
              <el-input v-model="form.not_owner_price" placeholder="非业主价格">
              </el-input
              ><span class="form_text">元</span>
            </el-form-item>
            <el-form-item
              label="单节结算价"
              v-if="form.commodity_type == '社区基础课'"
              prop="settlement_cash"
            >
              <el-input v-model="form.settlement_cash" placeholder="单节结算价">
              </el-input>
            </el-form-item>
            <el-form-item label="系统推荐最低价" v-if="form.system_price">
            {{form.system_price}}元
          </el-form-item>
          <el-form-item label="美团价格">
            <el-input v-model="form.mt_price" placeholder="美团价格总价"></el-input>
          </el-form-item>
          <el-form-item label="大众价格">
            <el-input v-model="form.dz_price" placeholder="大众点评总价"></el-input>
          </el-form-item>
           <p>课程售价为系统推荐价格，美团价格，大众价格的最低价格</p>
            <el-form-item label="课程售价">
             <el-input v-model="form.discount_amout"></el-input>

              <el-button type="text" @click="calcSalePrice">计算价格</el-button>
          </el-form-item>
            <el-form-item label="会员价">
             <el-input v-model="form.vip_price1"></el-input>
             <div>商会会员卡的价格</div>
          </el-form-item>
           <el-form-item label="会员超低价">
             <el-input v-model="form.vip_price2"></el-input>
              <div>海马会员卡的价格</div>
          </el-form-item>
            <el-form-item
              label="可用消费金"
              v-if="form.commodity_type == '社区基础课'"
            >
              <el-input
                placeholder="可用消费金"
                v-model="form.consume"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程类型" required>
              <el-radio-group v-model="form.is_recommend">
                <el-radio :label="false">普通课程</el-radio>
                <el-radio :label="true">优选课程</el-radio>
              </el-radio-group>
            </el-form-item>
              <el-form-item label="是否团课">
            <el-radio-group v-model="form.is_group">
              <el-radio :label="true">团课</el-radio>
              <el-radio :label="false">非团课</el-radio>
            </el-radio-group>
          </el-form-item>

            <el-form-item label="排课类型" prop="appointment_type">
              <el-radio-group v-model="form.appointment_type">
                <!-- <el-radio label="appointment">预约</el-radio> -->
                <el-radio label="fixed_scheduling">固定</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="课程提示">
              <el-input v-model="form.label"></el-input>
            </el-form-item>

            <el-form-item label="城市" v-if="mode == 'edit'">
              <el-cascader
                v-model="cityList"
                :options="cityoptions"
                :props="cityProps"
                :placeholder="cityList[1]"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面" required prop="face_url">
              <avatar-uploader
                @getImgUrl="getCover"
                :url="form.face_url"
                :clear="if_clear"
              ></avatar-uploader>
            </el-form-item>
            <el-form-item label="简介" required>
              <el-input
                type="textarea"
                v-model="form.introduction_content"
                placeholder="简介"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="正式课详情">
              <el-input
                type="textarea"
                v-model="form.introduction_text"
                placeholder="正式课详情"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="天赋课详情">
              <el-input
                type="textarea"
                v-model="form.introduction_talent"
                placeholder="天赋课详情"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="课程介绍图片" required>
              <images-uploader
                @getImgUrl="getProductImg"
                :urls="form.introduction_url"
                :clear="if_clear"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item label="开班人数" required="" prop="connect_peoplenum">
              <el-input
                type="number"
                v-model="form.connect_peoplenum"
                placeholder="最低开班人数"
              ></el-input
              ><span>人</span>
            </el-form-item>
            <el-form-item label="报名时间">
              <el-date-picker
                v-model="change_time"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="课时长" required="" prop="length_of_lesson">
              <el-input
                v-model="form.length_of_lesson"
                placeholder="课时长"
              ></el-input
              ><span>分钟</span>
            </el-form-item>
            <el-form-item label="有效期" required="" prop="duration">
              <el-input v-model="form.duration" placeholder="有效期"></el-input
              ><span>月</span>
            </el-form-item>
            <el-form-item label="适用年龄" required="" prop="apply_age">
              <el-input v-model="form.apply_age"></el-input>
            </el-form-item>
            <el-form-item label="适用人群" required="" prop="school_age">
              <el-select
                v-model="form.school_age"
                :placeholder="temp.school_age"
              >
                <el-option
                  v-for="(item, index) in school_age_list"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button v-if="mode == 'add'" type="primary" @click="submitForm()"
          >新 增</el-button
        >
        <el-button v-if="mode == 'edit'" type="primary" @click="updateForm()"
          >修 改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { ObjectChangedValue } from "@/js/utils";
import { courseRule } from "./communityRule";
import coachSelect from "../select/coachSelect.vue";

export default {
  components: {
    coachSelect,
  },
  data() {
    return {
      form: {},
      temp: {},
      type: "mechanism_offline",
      addDialog: false,
      if_clear: false,
      mechanism_id: "",
      mechanism_name_list: [],
      categories_child_list: [],
      rules: courseRule,
      age_type_list: [],
      school_age_list: [],
      cityoptions: [],
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      select_mechanism: [],
      checked: false,
      small_price: 0,
      can_consume: 0,
      change_time: [],
      mode: "add",
      coach_id: 0,
    };
  },
   watch: {
    'form.mt_price'(nv,ov){
          this.calcPrice()
    },
     'form.dz_price'(nv,ov){
          this.calcPrice()
    }
    },
  methods: {
    //初始化
    open(data) {
      if (data && data.id) {
        this.mode = "edit";
        this.form = data;
        this.editCourse();
      } else {
        this.form = {
          appointment_type: "fixed_scheduling",
          commodity_type: "社区基础课",
          is_group:true
        };
        this.select_mechanism = [];
        this.change_time = [];
        this.mode = "add";
      }
      this.mechanism_name_list = [];
      this.addDialog = true;
    },
    editCourse() {
      //正课数量
      this.$set(
        this.form,
        "formal_course_num",
        this.form.course_num - this.form.settlement_course
      );
      if (this.form.commodity_type == "社区公开课") {
        this.form.formal_course_num = this.form.course_num;
      } else {
        let amout = (this.form.mec_price / this.form.formal_course_num).toFixed(2);
        this.$set(this.form, "single_amout", amout);
      }
      this.cityList.push(this.form.province);
      this.cityList.push(this.form.city);
      this.cityList.push(this.form.district);
      this.change_time.push(this.form.start_time);
      this.change_time.push(this.form.end_time);
      this.temp = Object.assign({}, this.form);
    },
    // 机构全选
    selectAll(v) {
      if (v) {
        this.select_mechanism = this.mechanism_name_list.map((item) => {
          return item.id;
        });
        this.getAddress(this.mechanism_name_list[0]);
      } else {
        this.select_mechanism = [];
      }
    },
    getAddress(e) {
      this.cityList.push(e.province || "");
      this.cityList.push(e.city || "");
      this.cityList.push(e.district || "");
    },
     calcPrice(){
        if(!this.form.system_price || this.form.commodity_type == "社区公开课"){
          return
        }
        let price = this.form.system_price
        let settle =  this.form.settlement_cash * this.form.formal_course_num
        if(this.form.mt_price){
          if(settle <= this.form.mt_price){
            price = Math.min(this.form.mt_price,this.form.system_price)

          }
        }
         if(this.form.dz_price){
          if(settle <= this.form.mt_price){
            price = Math.min(this.form.dz_price,price)
          }
        }
        
        let mec_price = this.form.single_amout * this.form.formal_course_num
        if(price == this.form.system_price){
            this.form.amout = mec_price
            this.form.discount_amout = mec_price
            this.can_consume = Math.floor((mec_price - price) / 100) * 100;
            this.$set(this.form, "consume", this.can_consume);
        }else{
           this.$set(this.form, "consume", 0);
           this.form.amout = price
           this.form.discount_amout = price
        }
         
        this.$set(this.form, "mec_price", mec_price);
    },
    calcSalePrice(){
       if(this.form.commodity_type != "社区公开课"){
           this.getPoint()
        }
    },
    getPoint() {
      if (this.form.commodity_type == "社区公开课") {
        return;
      }
      if (this.form.single_amout || this.form.settlement_cash) {
        let url = "/user/masterSetPrice/querySettlePoint";
        this.$axios
          .get(url, {
            params: {
              card_type: "专享卡",
              amout: this.form.single_amout,
              settlement_cash: this.form.settlement_cash,
              type: "mechanism_offline",
            },
          })
          .then((res) => {
            this.$set(this.form, "pay_point", res.data.data.point);
              let point = res.data.data.point * this.form.formal_course_num;
              let amount = this.form.single_amout * this.form.formal_course_num;
              this.can_consume = Math.floor((amount - point * 10) / 100) * 100;
              if (this.form.is_community || this.form.card_type == "优学卡") {
                this.can_consume = 0;
              }
              this.$set(this.form, "system_price", (point * 10).toFixed(0));
              this.$set(this.form, "consume", this.can_consume);
              this.calcPrice()
          });
      }
    },
    changeCourseType(v) {
      if (v == "社区公开课") {
        this.form.card_type = "优学卡";
        this.$set(this.form,'discount_amout',0)
        this.$set(this.form,'consume',0)
      } else {
        this.form.card_type = "专享卡";
      }
    },
    submitForm() {
      let _this = this;
      console.log(this.$refs);
      this.$refs.myForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          console.log("success");
          _this.addNewCourse();
        } else {
          console.log("fail");
          _this.$message({
            type: "error",
            message: "请填写必要信息!",
          });
        }
      });
    },
    updateForm() {
      let _this = this;
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          _this.updateCourse();
        } else {
          _this.$message({
            type: "error",
            message: "请填写必要信息!",
          });
        }
      });
    },
    updateCourse() {
       if(!this.form.amout){
        this.$message('请设置课程售价')
        return
      }
      let url = "/user/masterSetPrice/update";
      this.form.sign_coach_id = this.coach_id || 0;
      if (this.form.commodity_type == "社区公开课") {
        this.form.card_type = "优学卡";
        this.form.discount_amout = 0;
        this.form.course_num = this.form.formal_course_num;
        this.form.settlement_course = this.form.formal_course_num;
        this.form.consume = 0;
      } else {
        this.form.course_num =
          parseInt(this.form.formal_course_num) +
          parseInt(this.form.settlement_course || 0);
        this.form.card_type = "专享卡";
        this.form.province = this.cityList[0];
        this.form.city = this.cityList[1];
        this.form.district = this.cityList[2];
      }

      if (this.mechanism_id) {
        this.from.mechanism_id = this.mechanism_id;
      }
      if (this.change_time.length > 0) {
        this.form.start_time = this.change_time[0];
        this.form.end_time = this.change_time[1];
      }

      const data = ObjectChangedValue(this.temp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      data.status = 2;
      this.$axios.post(url, { id: this.form.id, ...data }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.addDialog = false;
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    getCover(v) {
      this.form.face_url = v;
    },
    getProductImg(v) {
      this.form.introduction_url = v;
    },
    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      let data = {
        status: 2,
        type: 2,
        source: "课程",
      };
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },
    addNewCourse() {
       if(!this.form.amout){
        this.$message('请设置课程售价')
        return
      }
      let url = "/user/masterSetPrice/batchInsert";
      this.form.sign_coach_id = this.coach_id || null;
      if (this.form.commodity_type == "社区公开课") {
        this.form.course_num = this.form.formal_course_num;
        this.form.settlement_course = this.form.formal_course_num;
        this.form.card_type = "专享卡";
        this.form.discount_amout = 0;
        this.form.consume = 0;
      } else {
        this.form.course_num =
          parseInt(this.form.formal_course_num) +
          parseInt(this.form.settlement_course || 0);
        this.form.card_type = "专享卡";
        this.form.province = this.cityList[0];
        this.form.city = this.cityList[1];
        this.form.district = this.cityList[2];
      }
      this.form.deduction_course_num = 1;
      this.form.categories = "体育运动";
      this.form.type = "mechanism_offline";

      this.form.is_community = true;
      this.form.is_open = true;
      this.form.mechanism_ids = this.select_mechanism;
      if (this.mechanism_id) {
        this.from.mechanism_id = this.mechanism_id;
      }
      if (this.change_time.length > 0) {
        this.form.start_time = this.change_time[0];
        this.form.end_time = this.change_time[1];
      }

      this.form.status = 2;
      this.$axios.post(url, this.form).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "添加成功!" });
          this.addDialog = false;
          this.form = {};
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    getAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 10,
            currentPage: 1,
            platform: "年级",
          },
        })
        .then((res) => {
          this.age_type_list = res.data.data.rows;
        });
    },
    getSchoolAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 10,
            currentPage: 1,
            platform: "学龄",
          },
        })
        .then((res) => {
          this.school_age_list = res.data.data.rows;
        });
    },
    // 机构查询远程查询
    searchOrgName(query) {
      if (query && typeof query == "string") {
        let url = "/user/mastermechanism/queryByMessage";
        let data = {
          type: "teach_paypal",
          is_community: true,
          pageSize: 30,
          currentPage: 1,
          status: 2,
        };
        const numberReg = /^\d/;
        numberReg.test(query)
          ? (data.mechanism_telephone = query)
          : (data.mechanism_name = query);
        this.$axios
          .get(url, {
            params: data,
          })
          .then((res) => {
            this.mechanism_name_list = res.data.data.rows;
          });
      }
    },
  },
  mounted() {
    this.cityoptions = JSON.parse(localStorage.getItem("addrList"));
    this.getCateList();
    this.getAgeType();
    this.getSchoolAgeType();
  },
};
</script>
  
  <style lang="less">
  /deep/ .el-upload-list__item.is-ready {
display: none;
}
.el-table thead tr th {
  background-color: #eaeaea !important;
}

.overhide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-button {
  margin-bottom: 5px;
}

.el-button + .el-button {
  margin-left: 0;
}
</style>
  