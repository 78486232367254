<template>
  <div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="服务标题">
        <el-input v-model="title"></el-input>
      </el-form-item>
      <el-form-item label="所属机构" v-if="!venue_id">
        <mechanism-select :mecid.sync="mechanism_id"></mechanism-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="status" clearable>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="上架中" :value="1"></el-option>
          <el-option label="冻结" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getServiceList">查询</el-button>
        <el-button @click="openAdd" type="primary" style="margin-left: 30px"
          >新增</el-button
        >
      </el-form-item>
      <el-form-item>
        <excelExport
          ref="export"
          :tableName="tableName"
          :values="values"
          :titles="titles"
          @startExport="startExport"
        >
        </excelExport>
      </el-form-item>
    </el-form>
    <el-table :data="courseList" v-loading="isLoading">
      <el-table-column type="index"> </el-table-column>
      <el-table-column label="服务名称" prop="name"> </el-table-column>
      <el-table-column label="类目" prop="categories"> </el-table-column>
      <el-table-column label="服务次数" prop="num"></el-table-column>
      <el-table-column label="时长/小时" prop="duration"></el-table-column>
      <el-table-column label="购买数" prop="sold_out"></el-table-column>
      <el-table-column label="所属场馆" prop="venueName"></el-table-column>
      <el-table-column label="是否优选" prop="is_recommend">
        <template slot-scope="scope">
          {{ scope.row.is_recommend ? "优选" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.status == 0">待审核</el-tag>
          <el-tag type="success" v-if="scope.row.status == 1">上架中</el-tag>
          <el-tag type="warning" v-if="scope.row.status == 2">冻结</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" prop="" width="220">
        <template slot-scope="scope">
          <div class="flex flex-wrap">
            <el-button size="small" type="success" @click="toDetails(scope.row)"
              >详情</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="openServiceUpdate(scope.row)"
              >编辑</el-button
            >
            <el-button size="small" type="warning" @click="bind(scope.row)"
              >设备</el-button
            >
            <el-button
              size="small"
              type="danger"
              v-if="scope.row.status != 2"
              @click="frozen(scope.row, 2)"
              >冻结
            </el-button>
            <el-button
              size="small"
              type="success"
              v-if="scope.row.status != 1"
              @click="openServiceCheck(scope.row, 1)"
              >上架
            </el-button>
              <el-button size="small" v-if="scope.row.type == 1" type="success" @click="toAppointment(scope.row)"
              >预约排期</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <paging-fy
      @currentPageChange="currentPageChange"
      :currentPage="currentPage"
      :total="courseTotal"
    ></paging-fy>
    <addService
      ref="add"
      :data="curData"
      :mode="mode"
      @success="getServiceList"
    ></addService>

    <bindDevice :Service="curData" ref="device"></bindDevice>
  </div>
</template>

<script>
import addService from "./addService.vue";
import bindDevice from "./bindDevice.vue";
import excelExport from "@/components/excel/excelExport.vue";
export default {
  components: {
    addService,
    bindDevice,
    excelExport,
  },
  data() {
    return {
      title: "",
      mechanism_id: "",
      status: "",
      courseList: [],
      courseTotal: 0,
      isLoading: true,
      currentPage: 1,
      mechanism_name_list: "",
      mode: "add",
      curData: {},
      serviceType: {
        1: "预约",
        2: "次卡",
        3: "天数",
        4: "套餐",
      },
      tableName: "海马运动场馆服务",
      values: [
        "name",
        "categories",
        "num",
        "duration",
        "sold_out",
        "type",
        "is_recommend",
      ],
      titles: [
        "服务名称",
        "类目",
        "服务次数",
        "时长(小时)",
        "购买数",
        "类型",
        "是否优选",
      ],
    };
  },
  props: {
    venue_id: {
      type: [Number, String],
    },
  },

  mounted() {
    this.getServiceList();
  },

  methods: {
    toAppointment(row){
         this.$router.push({
          name:'serviceCreateAppointment',
          query:{
              vid: row.venue_id || null
          }
        })
    },
    // 导出表格
    startExport() {
      this.exportInfo();
    },
    exportInfo() {
      this.$axios({
        url: "/user/venue/server/query",
        params: {
          status: 1,
        },
      }).then((res) => {
        let DataList = res.data.data;
        DataList.forEach(item=>{
          item.type = this.serviceType[this.type]
        })
        this.$refs.export.importExcel(DataList);
      });
    },
    toDetails(row) {
      this.$router.push({
        name: "serveDetails",
        params: {
          id: row.id,
        },
      });
    },
    bind(row) {
      this.curData = row;
      this.$refs.device.open();
    },
    pass(row, status) {
      let url = "/user/masterSetPrice/update";
      this.$axios
        .post(url, {
          id: row.id,
          status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.getServiceList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    frozen(row, status) {
      let url = "/user/venue/server/update";
      this.$axios
        .post(url, {
          id: row.id,
          status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.getServiceList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    // 课程详情页
    toCouDetails(row) {
      let couMec = row.map.mechanismInfo.mechanism_name || "";
      this.$router.push({
        name: "couDetails",
        query: {
          type: "mec",
          couID: row.id,
          couMec,
        },
      });
    },
    // 更新
    openServiceUpdate(row) {
      this.mode = "edit";
      this.curData = row;
      this.$refs.add.open();
    },
    openServiceCheck(row) {
      this.mode = "check";
      this.curData = row;
      this.$refs.add.open();
    },
    openAdd() {
      this.mode = "add";
      this.curData = {};
      this.$refs.add.open();
    },
    getServiceList() {
      // let url = "/user/masterSetPrice/queryManagerListPage";
      let url = "/user/venue/server/queryManagerListPage";

      this.$axios
        .get(url, {
          params: {
            mechanism_id: this.mechanism_id || null,
            pageSize: 10,
            status: this.status === "" ? null : this.status,
            currentPage: this.currentPage,
            name: this.title || null,
          },
        })
        .then((res) => {
          this.courseList = res.data.data.rows;
          this.courseTotal = res.data.data.total;
          this.isLoading = false;
        });
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getServiceList();
    },
  },
};
</script>

<style lang="less" scoped></style>