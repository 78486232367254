export let courseRule ={
    title: [
      { required: true, message: "标题不能为空!", trigger: "change" },
    ],
    formal_course_num :[
        { required: true, message: "请输入课程节数", trigger: "change" },
      ],
    face_url :[
        { required: true, message: "请上传封面", trigger: "change" },
      ],
    categories_child: [
      { required: true, message: "请选择类目!", trigger: "change" },
    ],
    appointment_type: [
      { required: true, message: "请选择排课类型!", trigger: "change" },
    ],
    settlement_cash: [
      { required: true, message: "请填写结算金额", trigger: "change" },
    ],
    connect_peoplenum: [
      { required: true, message: "请填入开班数!", trigger: "change" },
    ],
    length_of_lesson: [
      { required: true, message: "请填入课时长!", trigger: "change" },
    ],
    duration: [
      { required: true, message: "请填入有效期!", trigger: "change" },
    ],
    apply_age: [
      { required: true, message: "请选择适用年龄!", trigger: "change" },
    ],
    school_age: [
      { required: true, message: "请选择适用适用人群!", trigger: "change" },
    ],
  }

