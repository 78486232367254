<template>
  <div>
    <el-dialog title="绑定设备" :visible.sync="show" width="30%">
      <el-checkbox-group v-model="device_ids">
        <ul class="infinite-list" style="overflow: auto">
          <li
            v-for="item in deviceList"
            class="infinite-list-item"
            :key="item.id"
          >
            <el-checkbox :label="item.id">
              {{ item.name }}
            </el-checkbox>
          </li>
        </ul>
      </el-checkbox-group>
      <div class="flex m-t20">
        <el-button class="m-r10" type="primary" @click="bindDevice">绑定</el-button>
        <el-button @click="show = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  props: {
    Service: {
      type: Object,
      default: null,
    },
  },
  watch:{
    Service:{
      immediate:true,
      deep:true,
      handler(newv){
        if(newv.id){
          this.getdeviceList();
          this.getServiceDevice()
        }
      }
    }
  },
  data() {
    return {
      deviceList: [],
      device_ids: [],
      query: null,
      show: false,
      currentPage: 1,
      content: "",
      name: "",
      addShow:false,
    };
  },
  methods: {
    getServiceDevice(){
      let url = "user/venue/device/getByServerId";
      this.$axios({
        url,
        params:{
          server_id: this.Service.id,
        }
        })
        .then((res) => {
           this.device_ids = res.data.data.map((item)=>{return item.id+''})
        });
    },
    open() {
      this.show = true;
    },
    addDevice() {
        if(this.name == ''){
            return
        }
        let url = "user/venue/device/insert";
      this.$axios
        .post(url, {
        venue_id: this.Service.venue_id,
        mechanism_id: this.Service.mechanism_id,
         name:this.name,
        })
        .then((res) => {
            this.addShow = false
         this.getdeviceList()
        });
    },
    bindDevice() {
      let url = "user/venue/server/update";
      this.$axios
        .post(url, {
          id: this.Service.id,
          device_ids: this.device_ids,
        })
        .then((res) => {
          this.show = false;
          this.$message(res.data.message);
        });
    },
    getdeviceList() {
      let url = "/user/venue/device/queryManagerListPage";
      this.$axios({
        url, 
          params: 
            {venue_id: this.Service.venue_id},
          },
      )
        .then((res) => {
          res.data.data.rows.forEach((item) => (item.id = item.id + ""));
          this.deviceList = res.data.data.rows;
        });
    },
  },
};
</script>
  
  <style>
</style>